var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  Drupal.behaviors.skuCategoriesV1 = {
    attach: function (context, settings) {
      var isMobile = !!settings && !!settings.is_mobile;

      if (isMobile) {
        return null;
      }
      var $wrap = $('.js-product__sku-categories', context);

      if ($wrap.length < 1) {
        return null;
      }
      var prodId = $wrap.data('product-id');

      if (!prodId) {
        return null;
      }
      var prodData = prodcat.data.getProduct(prodId);

      if (!prodData) {
        return null;
      }
      var collectionSkus = _.filter(prodData.skus, function (sku) {
        return !!sku['COLLECTION_NAME'];
      });

      if (collectionSkus.length < 1) {
        // There is only one tab in this case, so give it the active tab class, and exit
        $('.js-sku-categories-tab').first().addClass('resp-tab-active');

        return null;
      }
      var collections = _.groupBy(collectionSkus, 'COLLECTION_NAME');
      var $tabsList = $('.js-sku-categories-tabs');
      var $contents = $('.js-sku-categories-content');

      _.each(collections, function (collection, key) {
        var tabHtml = "<li class='js-sku-categories-tab product__sku-categories-nav-item'>";

        tabHtml += key;
        tabHtml += '</li>';
        $tabsList.append(tabHtml);
        var contentHtml = '<div>';
        var swatchListHtml = site.template.get({
          name: 'swatch_list_v1',
          data: {
            PRODUCT_ID: prodId,
            skus: collection
          }
        });

        contentHtml += swatchListHtml;
        contentHtml += '</div>';
        $contents.append(contentHtml);
      });

      $wrap.easyResponsiveTabs();
      // Below code is to select the first sku by default while clicking on each tab.
      var $tabsListHead = $('.js-sku-categories-tab');

      $tabsListHead.click(function () {
        Drupal.behaviors.skuCategoriesV1.defaultShadeSelect($tabsListHead);
      });
    },
    defaultShadeSelect: function (tabsListHead) {
      var skuCategory = tabsListHead.parents('div.js-product__sku-categories');
      var defaultSku = skuCategory.find('div.resp-tab-content-active .js-swatch-list li:first');
      var defaultSkuId = defaultSku.data('sku-base-id');

      $('.js-swatch-list-item-link[data-sku-base-id = ' + defaultSkuId + ']').trigger('click');
      var shadeText = $('.js-shade-updating-message-count').text().split(':')[0];

      shadeText += ':' + $('.js-sku-categories-content').find('.js-swatch-list-item:visible').length;
      $('.js-shade-updating-message-count').empty().append(shadeText);
    }
  };
  // Pre-order Tab Builder Section
  prodcat.ui.preOrderTabBuilder = function (prodData) {
    var $wrap = $('.js-product__sku-categories');

    $wrap.removeClass('pre-order-tab-content');
    if ($wrap.length < 1) {
      return null;
    }
    var prodId = $wrap.data('product-id');
    var allSkus = _.filter(prodData.skus, function (sku) {
      return sku;
    });
    var preOrderSkus = _.filter(prodData.skus, function (sku) {
      if (sku.isPreOrder == 1) {
        return sku;
      }
    });

    if (preOrderSkus.length > 0) {
      var $tabsList = $('.js-sku-categories-tabs');

      $wrap.addClass('pre-order-tab-content');
      if (!Drupal.settings.is_mobile) {
        $tabsList.removeClass('hidden');
      }
      $('.js-sku-count-all').text(allSkus.length);
      var preOrder = _.groupBy(preOrderSkus, 'isPreOrder');
      var $contents = $('.js-sku-categories-content');
      var $skuCount = preOrderSkus.length;

      $tabsList.removeClass('hidden');
      _.each(preOrder, function (collection) {
        var tabHtml = "<li class='js-sku-categories-tab product__sku-categories-nav-item pre-order-tab'>";

        tabHtml += site.translations.product.preorder;
        tabHtml += "<span class='sku-count'>" + $skuCount + '</span></li>';
        $tabsList.append(tabHtml);
        var contentHtml = '<div>';
        var swatchListHtml = site.template.get({
          name: 'swatch_list_v1',
          data: {
            PRODUCT_ID: prodId,
            skus: collection
          }
        });

        contentHtml += swatchListHtml;
        contentHtml += '</div>';
        $contents.append(contentHtml);
      });
      $wrap.easyResponsiveTabs();
    }
    // Below code is to select the first sku by default while clicking on each tab for Mobile.(only for Preorder products not for regular products)
    // Adding Condition for quickshop pages pc, since collection tabs loads only in SPP pages.
    var $tabsListHead = $('.js-sku-categories-tab');

    if (Drupal.settings.is_mobile || (!Drupal.settings.is_mobile && $tabsListHead.parents('div.js-quickshop'))) {
      $tabsListHead.click(function () {
        Drupal.behaviors.skuCategoriesV1.defaultShadeSelect($tabsListHead);
      });
    }
  };
})(jQuery);
